import React from "react";

function App() {
  return (
    <main className="h-screen flex w-full justify-center items-center flex-col px-4 text-center">
      <h1 className="text-2xl font-bold">Hello! 👋, I'm Sumnima Rai</h1>
      <p className="text-lg">
        The prettiest girl with the most handsome boyfriend
      </p>
    </main>
  );
}

export default App;
